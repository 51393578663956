/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
import '../components/formulario.css';
import pin from '../assets/map-pin.svg';
import mail from '../assets/mail.svg';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import '../components/mapa.css';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-icon.png";
// import axios frmzz

export const Formulario = () => {
    let iconUbicacion = new L.icon({
        iconUrl: icon,
        iconShadow: iconShadow,
        iconSize: [40, 55], // size of the icon
        iconAnchor: [22, 94],
        shadowAnchor: [22, 94],
        popupAnchor: [-3, -76]
    })

    return (
        <>
            <section className='formulario'>
                <div className='row'>
                    <div className='contentWrapper1'>
                        <div className='content'>
                            <h2>Contacto</h2>
                            <p>Si deseas consultar mayor información sobre nuestros servicios o tienes alguna duda al respecto, no dudes en solicitar una consultoría gratuita y uno de nuestros asesores te contactará a la brevedad.</p>
                            <img src={mail} className='pin'></img>
                            <p>Correo electrónico: <br/><b>quesosycremas1@gmail.com</b></p>
                            <img src={pin} className='pin'></img>
                            <p>Nos ubicamos en Calle Nayarit y Calle Acapulco #1401 de la Colonia Pueblo Nuevo en la ciudad de Mexicali B.C. C.P 21120</p>

                        </div>
                    </div>
                    <div className='mapa-1'>
                        <MapContainer center={[32.654445941063, -115.49944072433816]} zoom={17} scrollWheelZoom={false} className='mapa-1'>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <Marker position={[32.654445941063, -115.49944072433816]} icon={iconUbicacion}>
                                <Popup>
                                    Quesos Buenrostro
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
            </section>
        </>
    )
}