/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

/*Banner, Carousel*/
import Quesos from '../assets/buenrostro.png';
import Prueba4 from '../assets/quesos1.jpg';
import Prueba5 from '../assets/quesos2.jpg';
import Prueba6 from '../assets/quesos3.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './banner.css';

/*Ubicación*/
import '../components/sucursales.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import '../components/mapa.css';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-icon.png";



/*Productos*/
import '../components/productos.css';
import Quesos1 from '../assets/productos/quesos.png'
import Quesos2 from '../assets/productos/panela.png'
import Quesos3 from '../assets/productos/quesomonterrey1.png'

/*Misión, Visión y Valores */
import '../components/cards.css';
import Vision from '../assets/vision.png';
import Mision from '../assets/mision.png';
import Valor from '../assets/valor.png';


export const Home = () => {
    let iconUbicacion = new L.icon({
        iconUrl: icon,
        iconShadow: iconShadow,
        iconSize: [40, 55], // size of the icon
        iconAnchor: [22, 94],
        shadowAnchor: [22, 94],
        popupAnchor: [-3, -76]
    })
    return (
        <><><>
            <section className='contenedor'>
                <div className='row'>
                    <div className='imgLogo'>
                        <img src={Quesos} ></img>
                    </div>
                    <div className='contentWrapper'>
                        <div className='content'>
                            <h2>¿Quiénes somos?</h2>
                            <p>Somos una empresa dedicada a la elaboración y venta de productos lácteos, de la más alta calidad, productos que garantizan la salubridad e higiene de nuestros productos.</p>
                            <p>Estamos ubicados en Calle Nayarit y Calle Acapulco #1401 de la Colonia Pueblo Nuevo en la ciudad de Mexicali B.C. C.P 21120</p>
                        </div>
                    </div>

                </div>
                <div className='container bg-transparent  pb-3'>
                    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="10000">
                                <img src={Prueba4} className="d-block w-100" alt="..."></img>
                            </div>
                            <div className="carousel-item" data-bs-interval="10000">
                                <img src={Prueba5} className="d-block w-100" alt="..."></img>
                            </div>
                            <div className="carousel-item">
                                <img src={Prueba6} className="d-block w-100" alt="..."></img>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>

            <section className='contenedor2'>
                <div className='cartas__textos'>
                    <h2>¿Dónde nos ubicamos?</h2>
                </div>
                <div className='container bg-transparent'>
                    <div className='contenedorMapa'>
                    <MapContainer center={[32.654445941063, -115.49944072433816]} zoom={16} scrollWheelZoom={false} className='mapa'>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[32.654445941063, -115.49944072433816]} icon={iconUbicacion}>
                            <Popup>
                                Quesos Buenrostro
                            </Popup>
                        </Marker>
                    </MapContainer>
                    </div>
                </div>
            </section>

            <section className='contenedorProductos'>
                <h3 className='contenedorProductos_titulo'>Nuestros Productos</h3>
                <div className='us-offer'>
                    <div className='offer'>
                        <div className='offer-img'>
                            <img src={Quesos1}></img>
                        </div>
                        <div className='offer-price'>
                            {/* <h3 className='price-subtitle'>Queso</h3> */}
                            <div className='price-price'>
                                <h3>Queso Fresco</h3>
                            </div>
                            <p>Quesos Buenrostro</p>
                        </div>
                    </div>
                    <div className='offer'>
                        <div className='offer-img'>
                            <img src={Quesos2}></img>
                        </div>
                        <div className='offer-price'>
                            {/* <h3 className='price-subtitle'>Queso</h3> */}
                            <div className='price-price'>
                                <h3>Queso Panela</h3>
                            </div>
                            <p>Quesos Buenrostro</p>
                        </div>
                    </div>
                    <div className='offer'>
                        <div className='offer-img'>
                            <img src={Quesos3}></img>
                        </div>
                        <div className='offer-price'>
                            {/* <h3 className='price-subtitle'>Queso</h3> */}
                            <div className='price-price'>
                                <h3>Queso Monterrey</h3>
                            </div>
                            <p>Quesos Buenrostro</p>
                        </div>
                    </div>
                </div>
            </section></>

            <section className='contenedor4'>
                <div className='cartas__textos1'>
                    <h2>Misión, Visión y Valores para Quesos Buenrostro</h2>
                </div>
                <div className="container bg-transparent">
                    <div className="card2">
                        <div className="left-column background1-left-column">
                            <h6>Quesos Buenrostro</h6>
                            <img src={Mision} className='mision'></img>
                            <i className="fa fa-github"></i>
                        </div>
                        <div className="right-column2">
                            <div>
                                <h4></h4>
                                <h6></h6>
                            </div>
                            <h2>Misión</h2>
                            <p>La elaboración y comercialización de productos lácteos de la más alta calidad, contribuyendo a la buena nutrición y salud de la población superando siempre las expectativas de nuestros clientes y consumidores. </p>
                        </div>
                    </div>
                    <div className="card2">
                        <div className="left-column background2-left-column">
                            <h6>Quesos Buenrostro</h6>
                            <img src={Vision} className='vision'></img>
                            <i className="fa fa-android" aria-hidden="true"></i>
                        </div>
                        <div className="right-column2">
                            <div>
                                <h4></h4>
                                <h6></h6>
                            </div>
                            <h2>Visión</h2>
                            <p>Ser una empresa líder en el mercado con reconocimiento a nivel regional por productos lácteos y procesos artesanales, satisfaciendo las necesidades alimentarias de la población con productos de la más alta calidad.</p>
                        </div>
                    </div>
                </div>
                <div className='container bg-transparent'>
                    <div className="card2">
                        <div className="left-column background3-left-column">
                            <h6>Quesos Buenrostro</h6>
                            <img src={Valor} className='valores'></img>
                            <i className="fa fa-android" aria-hidden="true"></i>
                        </div>
                        <div className="right-column2">
                            <div>
                                <h4></h4>
                                <h6></h6>
                            </div>
                            <h2>Valores</h2>
                            <li>Valoramos la honestidad.</li>
                            <li>El trabajo en equipo</li>
                            <li>El compromiso con nuestros clientes, garantizando la excelencia de nuestros productos y procesos</li>
                            <li>La integridad de nuestro personal y de la empresa; brindando estabilidad y desarrollo a nuestros colaboradores y a sus familias.</li>
                        </div>
                    </div>
                </div>
            </section></>
        </>
    )
}
