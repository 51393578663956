/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import '../components/productos.css';
import Quesos1 from '../assets/productos/quesos.png'
import Quesos2 from '../assets/productos/panela.png'
import Quesos3 from '../assets/productos/quesomonterrey1.png'

export const Productos = () => {
  return (
    <section className='contenedorProductos'>
      <h3 className='contenedorProductos_titulo'>Nuestros Productos</h3>
      <div className='us-offer'>
        <div className='offer'>
          <div className='offer-img'>
            <img src={Quesos1}></img>
          </div>
          <div className='offer-price'>
            {/* <h3 className='price-subtitle'>Queso</h3> */}
            <div className='price-price'>
              <h3>Queso Fresco</h3>
            </div>
            <p>Quesos Buenrostro</p>
          </div>
        </div>
        <div className='offer'>
          <div className='offer-img'>
            <img src={Quesos2}></img>
          </div>
          <div className='offer-price'>
            {/* <h3 className='price-subtitle'>Queso</h3> */}
            <div className='price-price'>
              <h3>Queso Panela</h3>
            </div> 
            <p>Quesos Buenrostro</p>
          </div>
        </div>
        <div className='offer'>
          <div className='offer-img'>
            <img src={Quesos3}></img>
          </div>
          <div className='offer-price'>
            {/* <h3 className='price-subtitle'>Queso</h3> */}
            <div className='price-price'>
              <h3>Queso Monterrey</h3>
            </div>
            <p>Quesos Buenrostro</p>
          </div>
        </div>
      </div>
    </section>
  )
}
